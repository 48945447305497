import { GetterTree } from "vuex";
import {
  Education,
  Experience,
  Profile,
  RootState,
  Skill,
  Social,
  Work
} from "@/model";

export type Getters<S = Profile> = {
  educationInfo(state: S): Array<Education>;
  emailId(state: S): string;
  experienceInfo(state: S): Array<Experience>;
  knowledgeStack(state: S): Array<string>;
  myWorkInfo(state: S): Array<Work>;
  name(state: S): string;
  resumeLink(state: S): string;
  skillByType: (state: S) => (type: string) => Array<Skill>;
  socialTags(state: S): Array<Social>;
  stackInfo(state: S): Array<string>;
};

export const getters: GetterTree<Profile, RootState> & Getters = {
  educationInfo: (state: Profile) => state.education,
  emailId: (state: Profile) => state.contactInfo.email,
  experienceInfo: (state: Profile) => state.experience,
  knowledgeStack: (state: Profile) => state.aboutMe.knowledge,
  myWorkInfo: (state: Profile) => state.myWork,
  name: (state: Profile) => state.name,
  resumeLink: (state: Profile) => state.resume,
  skillByType: (state: Profile) => (type: string) =>
    state.skills.filter((skill: Skill) => skill.skillType === type),
  socialTags: (state: Profile) => state.contactInfo.social,
  stackInfo: (state: Profile) => state.aboutMe.profile
};
