
import { Options, Vue } from "vue-class-component";
import { Skill } from "@/model";
import { useStore } from "@/store";
import SkillSet from "./SkillSet.vue";

@Options({
  name: "Skills",
  components: {
    SkillSet,
  },
})
export default class Skills extends Vue {
  store = useStore();

  filterData = (skillsList: Array<Skill>) => {
    let content = "";
    const contentToReturn = skillsList.map((skill, index) => {
      if (content === "" || (index + 1) % 3 == 0) content = skill.name;
      else {
        content = `${content}, ${skill.name}`;
      }
      if ((index + 2) % 3 == 0 || index === skillsList.length - 1)
        return content;
      return "";
    });
    return contentToReturn.filter((content) => content !== "");
  };

  get frontEndTechnologies(): Array<string> {
    return this.filterData(this.store.getters.skillByType("FE"));
  }

  get backEndTechnologies(): Array<string> {
    return this.filterData(this.store.getters.skillByType("BE"));
  }

  get cloudTechnologies(): Array<string> {
    return this.filterData(this.store.getters.skillByType("CL"));
  }

  get mobileTechnologies(): Array<string> {
    return this.filterData(this.store.getters.skillByType("MB"));
  }
}
