
import { Options, Vue } from "vue-class-component";
import { ActionTypes as ProfileActions } from "@/store/modules/profile";
import { useStore } from "@/store";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Resume from "@/components/Resume/Resume.vue";

@Options({
  name: "App",
  components: {
    Footer,
    Header,
    Resume,
  },
})
export default class App extends Vue {
  store = useStore();

  created() {
    this.store.dispatch(ProfileActions.updateProfile);
  }
}
