
import { Options, Vue } from "vue-class-component";

@Options({
  name: "About",
  props: {
    icon: String,
    link: String,
    name: String,
    details: String,
    technology: Array,
  },
})
export default class About extends Vue {
  icon!: string;
  link!: string;
  name!: string;
  details!: string;
  technology!: string[];
}
