
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Work } from "@/model";
import MyWork from "./MyWork.vue";

@Options({
  name: "About",
  components: {
    MyWork,
  },
})
export default class About extends Vue {
  store = useStore();

  get knowledgeArray(): Array<String> {
    return this.store.getters.knowledgeStack;
  }

  get myWorkArray(): Array<Work> {
    return this.store.getters.myWorkInfo;
  }
}
