import { createApp } from "vue";
import AOS from "aos";
import App from "@/components/App.vue";
import store, { key } from "@/store";
import { initialize } from "./firebase";

import "aos/dist/aos.css";

initialize();

createApp(App)
  .use(AOS.init({ disable: window.innerWidth < 600 }))
  .use(store, key)
  .mount("#app");
