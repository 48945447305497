
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { ActionTypes as ProfileActions } from "@/store/modules/profile";

@Options({
  name: "ContactMe",
  props: {
    formSubmitted: Function,
  },
})
export default class ContactMe extends Vue {
  store = useStore();
  name: string = "";
  email: string = "";
  message: string = "";
  invalidMessages: string[] = [];
  formSubmitted!: () => void;

  isFormValid() {
    const mailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.invalidMessages = [];
    if (!this.name || !this.email || !this.message) {
      this.invalidMessages.push("All fields are necessary.");
      return false;
    }
    let returnValue = true;
    if (!!this.name && this.name.trim().length < 2) {
      this.invalidMessages.push("Full Name is very short!!!");
      returnValue = false;
    }
    if (!!this.email && !this.email.trim().match(mailformat)) {
      this.invalidMessages.push("Invalid email address!!!");
      returnValue = false;
    }
    if (!!this.message && this.message.trim().length < 2) {
      this.invalidMessages.push("Message is very short!!!");
      returnValue = false;
    }
    return returnValue;
  }

  submitForm() {
    if (this.isFormValid()) {
      const message = {
        email: this.email,
        message: this.message,
        name: this.name,
      };
      this.store.dispatch(ProfileActions.addMessage, message);
      this.email = "";
      this.message = "";
      this.name = "";
      this.formSubmitted();
    }
  }
}
