
import { Options, Vue } from "vue-class-component";
import { Education, Experience as Exp } from "@/model";
import { useStore } from "@/store";
import TimelineStep from "./TimelineStep.vue";

@Options({
  name: "Experience",
  components: {
    TimelineStep,
  },
})
export default class Experience extends Vue {
  store = useStore();

  get educationArray(): Array<Education> {
    return this.store.getters.educationInfo;
  }

  get experienceArray(): Array<Exp> {
    return this.store.getters.experienceInfo;
  }
}
