
import { Options, Vue } from "vue-class-component";

@Options({
  name: "SkillSet",
  props: {
    skillName: String,
    skillSet: Array,
  },
})
export default class SkillSet extends Vue {
  skillName!: string;
  skillSet!: Array<string>;
}
