import { FirebaseApp, initializeApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDWi69MrSvJtTxQSoQKV8IraaAs6qkgJXk",
  authDomain: "yogiraj-pujari-27491.firebaseapp.com",
  databaseURL: "https://yogiraj-pujari-27491.firebaseio.com",
  projectId: "yogiraj-pujari-27491",
  storageBucket: "yogiraj-pujari-27491.appspot.com",
  messagingSenderId: "108950750436",
  appId: "1:108950750436:web:d580c1e6840027e3fa18f1"
};

let app: FirebaseApp, database: Database;

const initialize = () => {
  app = initializeApp(firebaseConfig);
  database = getDatabase(app);
};

export { app, database, initialize };
