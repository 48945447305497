import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { RootState } from "@/model";
import profile, { Store as ProfileStore } from "./modules/profile";

export type RootStore = ProfileStore<Pick<RootState, "profile">>;

export const key: InjectionKey<Store<RootState>> = Symbol();

const store = createStore<RootState>({
  modules: {
    profile
  }
});

export const useStore = (): RootStore => baseUseStore(key);

export default store;
