
import { Options, Vue } from "vue-class-component";

@Options({
  name: "TimelineStep",
  props: {
    content: String,
    extraDetails: String,
    name: String,
    year: Number,
  },
})
export default class TimelineStep extends Vue {
  content!: string;
  extraDetails!: string;
  name!: string;
  year!: number;
}
