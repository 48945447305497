
import { Options, Vue } from "vue-class-component";
import { Social } from "@/model";
import { useStore } from "@/store";
import ConnectTag from "./ConnectTag.vue";
import ContactMe from "./ContactMe.vue";

@Options({
  name: "Footer",
  components: {
    ConnectTag,
    ContactMe,
  },
})
export default class Footer extends Vue {
  store = useStore();
  messageSent: boolean = false;

  get socialContent(): Array<Social> {
    return this.store.getters.socialTags;
  }

  get resumeTag(): string {
    return this.store.getters.resumeLink;
  }

  get mailLink(): string {
    return `mailto:${this.store.getters.emailId}`;
  }

  setMessageSent() {
    this.messageSent = true;
  }
}
