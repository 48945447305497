import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-878615a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-aos": "slide-up",
  "data-aos-easing": "ease"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createElementVNode("legend", null, _toDisplayString(_ctx.skillName), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skillSet, (tech, index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: { index },
        "data-aos": "fade-left",
        "data-aos-easing": "ease"
      }, _toDisplayString(tech), 1))
    }), 128))
  ]))
}