import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("h1", null, "Yogiraj Pujari", 512), [
          [_vShow, _ctx.headerVisibility]
        ])
      ]),
      _: 1
    })
  ]))
}