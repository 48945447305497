import { MutationTree } from "vuex";
import { Profile } from "@/model";
import { UPDATE_PROFILE } from "./mutation-types";

export type Mutations = {
  [UPDATE_PROFILE](state: Profile, payload: Profile): void;
};

export const mutations: MutationTree<Profile> = {
  [UPDATE_PROFILE](state: Profile, payload: Profile) {
    state.aboutMe = payload.aboutMe;
    state.contactInfo = payload.contactInfo;
    state.education = payload.education;
    state.email = payload.email;
    state.experience = payload.experience;
    state.myWork = payload.myWork;
    state.name = payload.name;
    state.picture = payload.picture;
    state.resume = payload.resume;
    state.skills = payload.skills;
  }
};
