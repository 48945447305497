
import { Options, Vue } from "vue-class-component";
import About from "./About/About.vue";
import Experience from "./Experience/Experience.vue";
import Introduction from "./Introduction/Introduction.vue";
import Skills from "./Skills/Skills.vue";

@Options({
  name: "Resume",
  components: {
    About,
    Experience,
    Introduction,
    Skills,
  },
})
export default class Resume extends Vue {}
