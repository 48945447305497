
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";

let timeoutTrigger;

@Options({
  name: "Introduction",
})
export default class Introduction extends Vue {
  store = useStore();
  private idx: number = 0;

  created() {
    timeoutTrigger = setInterval(() => {
      this.idx = this.idx + 1;
      if (this.idx === this.store.getters.stackInfo.length) {
        this.idx = 0;
      }
    }, 2500);
  }

  get fullName(): string {
    return this.store.getters.name;
  }

  get currentStack(): string {
    return this.store.getters.stackInfo[this.idx];
  }

  unmounted() {
    clearInterval(timeoutTrigger);
  }
}
