import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_About = _resolveComponent("About")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Experience = _resolveComponent("Experience")!

  return (_openBlock(), _createElementBlock("article", null, [
    _createVNode(_component_Introduction),
    _createVNode(_component_About),
    _createVNode(_component_Skills),
    _createVNode(_component_Experience)
  ]))
}