import { ActionContext, ActionTree } from "vuex";
import { database } from "@/firebase";
import { DataSnapshot, onValue, push, ref, set } from "firebase/database";
import { Message, Profile, RootState } from "@/model";
import { Mutations } from "./mutations";
import { UPDATE_PROFILE } from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<Profile, RootState>, "commit">;

export enum ActionTypes {
  addMessage = "GENERIC_ADD_MESSAGE",
  updateProfile = "GENERIC_UPDATE_PROFILE"
}

export interface Actions {
  [ActionTypes.addMessage](
    { commit }: AugmentedActionContext,
    message: Message
  ): any;
  [ActionTypes.updateProfile]({ commit }: AugmentedActionContext): any;
}

export const actions: ActionTree<Profile, RootState> = {
  [ActionTypes.addMessage]({ commit }, message): any {
    const messageListRef = ref(database, "messages");
    const newMessageRef = push(messageListRef);
    set(newMessageRef, message);
  },
  [ActionTypes.updateProfile]({ commit }): any {
    const profileRef = ref(database, "profile");
    onValue(profileRef, (snapshot: DataSnapshot) => {
      commit(UPDATE_PROFILE, snapshot.val());
    });
  }
};
