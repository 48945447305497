
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Header",
})
export default class Header extends Vue {
  showHeader: boolean = false;

  get headerVisibility() {
    return this.showHeader;
  }

  created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    this.showHeader = window.scrollY >= window.innerHeight;
  }
}
