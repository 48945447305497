import {
  CommitOptions,
  DispatchOptions,
  Module,
  Store as VuexStore
} from "vuex";
import { actions, ActionTypes, Actions } from "./actions";
import { getters, Getters } from "./getters";
import { mutations, Mutations } from "./mutations";
import { Profile, RootState } from "@/model";

export const state: Profile = {
  aboutMe: {
    firstDayOfWork: "",
    knowledge: [],
    profile: []
  },
  contactInfo: {
    email: "",
    social: []
  },
  education: [],
  email: "",
  experience: [],
  myWork: [],
  name: "",
  picture: "",
  resume: "",
  skills: []
};

const profile: Module<Profile, RootState> = {
  state,
  mutations,
  actions,
  getters
};

type Store<S = Profile> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export { ActionTypes, Store };
export default profile;
