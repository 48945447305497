
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ConnectTag",
  props: {
    icon: String,
    link: String,
    name: String,
  },
})
export default class ConnectTag extends Vue {
  icon!: string;
  link!: string;
  name!: string;
}
